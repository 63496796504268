import styles from "./PeopleCountRadio.module.css";

const PeopleCountRadio = ({ peopleCount, onChange }) => {
  return (
    <section className={styles.container}>
      <span className={styles.title}>Antall låntakere</span>

      <section className={styles.inputs}>
        <input
          type="radio"
          name="peopleCount"
          id="peopleCount_1"
          value="1"
          checked={+peopleCount === 1}
          onChange={(e) => onChange(e.target.value)}
        />
        <label htmlFor="peopleCount_1">1 låntaker</label>

        <input
          type="radio"
          name="peopleCount"
          id="peopleCount_2"
          value="2"
          checked={+peopleCount === 2}
          onChange={(e) => onChange(e.target.value)}
        />
        <label htmlFor="peopleCount_2">2 låntakere</label>
      </section>
    </section>
  );
};

export default PeopleCountRadio;
