import styles from "./InputWithSlider.module.css";
import { Range } from "react-range";

const InputWithSlider = ({
  value,
  onChange,
  max,
  formatToCurrency,
  label,
  subLabel,
}) => {
  return (
    <section className={styles.container}>
      <label id={label} className={styles.label}>
        <span>{label}</span>
        <span className={styles.subLabel}>{subLabel}</span>
      </label>

      <div className={styles.sliderContainer}>
        <Range
          min={0}
          max={max}
          step={5000}
          values={[value]}
          onChange={(e) => onChange(String(e))}
          renderTrack={({ props, children }) => (
            <div {...props} className={styles.track}>
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div {...props} className={styles.thumb} aria-labelledby={label} />
          )}
        />
      </div>

      <div className={styles.numberInputContainer}>
        <input
          type="text"
          inputMode="numeric"
          value={formatToCurrency(value)}
          onChange={(e) => onChange(e.target.value)}
          className={styles.numberInput}
          onFocus={(e) => e.target.select()}
          aria-labelledby={label}
        />
      </div>
    </section>
  );
};

export default InputWithSlider;
